<!--  -->
<template>
  <div class="main_box">
    <div class="nav_menu">
      <div class="menu_left">
        <el-input v-model="value" placeholder="请输入用户名称"></el-input>
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button type="info" @click="handleReset">重置</el-button>
      </div>
      <div class="menu_right">
        <el-button type="success" @click="userAdd">新增用户</el-button>
      </div>
    </div>
    <div>
      <el-table
        :header-cell-style="{
          'background-color': '#4073b6',
          color: '#FFFFFF'
        }"
        :data="tableData"
        stripe
        style="width: 100%"
        v-loading="loading"
        element-loading-text="数据量过大，加载中..."
      >
        <el-table-column type="index" prop="sid" label="序号" width="95">
        </el-table-column>
        <el-table-column prop="userName" label="用户名称"> </el-table-column>
        <el-table-column prop="roleName" label="角色名称"> </el-table-column>
        <el-table-column prop="departmentName" label="部门名称">
        </el-table-column>
        <el-table-column prop="phone" label="联系电话"> </el-table-column>
        <el-table-column width="250" label="操作">
          <template slot-scope="scope">
            <el-button class="edit" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button class="delete" @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next, jumper"
        :total="total"
        @current-change="handleCurrent"
      >
      </el-pagination>
    </div>
    <!-- 新增用户 -->
    <el-dialog
      class="border-r-8"
      :visible.sync="addShow"
      width="calc(388/1920*100vw)"
      @close="resetForm('ruleForm')"
    >
      <div slot="title" class="dialogAdd t-x font-s-18">
        {{ title }}
      </div>
      <div class="contentAdd">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          hide-required-asterisk
        >
          <el-form-item
            label="用户名称"
            prop="userName"
            style="height:calc(35/1080*100vw)"
          >
            <el-input
              v-model="ruleForm.userName"
              maxlength="20"
              placeholder="请输入用户名称"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="角色名称"
            prop="roleName"
          
          >
            <el-select placeholder="请选择" v-model="ruleForm.roleName">
              <el-option
                v-for="item in options"
                :key="item.id"
                :value="item.nickName"
                :label="item.nickName"
              ></el-option>
            </el-select>
          </el-form-item>
           <el-checkbox v-model="checked"  style="height:calc(20/1080*100vw);margin-left:120px">是否自定义部门名称</el-checkbox>
          <el-form-item
            label="部门名称"
            prop="departmentName"
            style="height:calc(35/1080*100vw)"
          >
            <el-select
              v-if="!checked"
              placeholder="请选择部门名称"
              v-model="ruleForm.departmentName"
            >
              <el-option
                v-for="item in departList"
                :key="item.id"
                :value="item.name"
                :label="item.name"
              ></el-option>
            </el-select>
            <el-input
             v-else
             v-model="ruleForm.departmentName"
              placeholder="请输入自定义部门名称"
            ></el-input>
          </el-form-item>
          
          <el-form-item
            label="联系电话"
            prop="phone"
            style="height:calc(35/1080*100vw)"
            maxlength="11"
          >
            <el-input
              v-model="ruleForm.phone"
              placeholder="请输入联系电话"
              oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
              maxlength="11"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetForm('ruleForm')" class="guanBi"
          >关闭</el-button
        >
        <el-button
          v-if="title == '用户管理-新增'"
          type="primary"
          style="margin-left:calc(30/1920*100vw)"
          @click="handleAdd"
          >保存</el-button
        >
        <el-button v-else type="primary" @click="userEnit">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  userAdd,
  userUpdate,
  userFindAll,
  userDepartment
} from "../../../api/user";
import { userFall } from "./validate";
var isUserFall = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入降雨量"));
  } else if (!userFall(value)) {
    callback(new Error("请输入正确的降雨量"));
  } else {
    callback();
  }
};

export default {
  components: {},
  data() {
    return {
      tableData: [], //用户列表
      checked:false,
      title: "",
      page: 1,
      size: 10,
      total: 0, //总条数
      addShow: false,
      ruleForm: {
        userName: "", //用户名
        departmentName: "", //部门名
        roleName: "",
        phone: "", //手机号
        roleId: "", //角色id
        departmentId: "" // 部门id
      },
      rules: {
        userName: [
          { required: true, message: "请输入用户名称", trigger: "change" }
        ],
        roleName: [
          { required: true, message: "请输入角色名称", trigger: "input" }
        ],
        departmentName: [
          { required: true, message: "请输入部门名称", trigger: "input" }
        ],
        phone: [
          { required: true, message: "请输入联系电话", trigger: "input" },
          {
            required: true,
            message: "请输入正确的联系电话",
            trigger: "input",
            validator: isUserFall
          }
        ]
      },
      options: [], //角色下拉框
      departList: [], // 部门下拉框
      value: "",
      loading: true
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 新增
    userAdd() {
      this.ruleForm = {};
      this.addShow = true;
      this.title = "用户管理-新增";
    },
    // 获取下拉菜单的角色名
    getUser() {
      this.options.forEach(item => {
        if (item.id == this.ruleForm.roleId) {
          this.ruleForm.roleName = item.nickName;
        }
      });
      // 部门下拉
      this.departList.forEach(item => {
        if (item.id == this.ruleForm.departmentId) {
          this.ruleForm.departmentName = item.name;
        }
      });
    },
    handleAdd() {
      this.options.forEach(item => {
        if (item.nickName == this.ruleForm.roleName) {
          this.ruleForm.roleId = item.id;
        }
      });
      // 部门下拉
      this.departList.forEach(item => {
        if (item.name == this.ruleForm.departmentName) {
          this.ruleForm.departmentId = item.id;
        }
      });
      let type = this.checked?2:1;
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          userAdd({
            userName: this.ruleForm.userName,
            departmentName: this.ruleForm.departmentName,
            roleId: this.ruleForm.roleId,
            phone: this.ruleForm.phone,
            type:type,
            roleName: this.ruleForm.roleName,
            departmentId: this.ruleForm.departmentId
          })
            .then(res => {
              this.$message.success(res.message);
              this.addShow = false;
              this.getUserList();
            })
            .catch(err => {
              this.$message.error(err.message);
            });
        }
      });
    },
    // 编辑
    handleEdit(item) {
      console.log(item);
      this.ruleForm = {};

      // this.getUser();
      this.addShow = true;
      this.title = "用户管理-编辑";
      // 深拷贝
      this.ruleForm = JSON.parse(JSON.stringify(item));
    },
    // 编辑保存
    userEnit() {
      this.options.forEach(items => {
        if (this.ruleForm.roleName == items.nickName) {
          this.ruleForm.roleId = items.id;
        }
      });
      // 部门下拉
       this.departList.forEach(item => {
        if (item.name == this.ruleForm.departmentName) {
        this.ruleForm.departmentId = item.id;
        }
      });
      let type = this.checked?2:1;
      console.log(this.ruleForm);
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          userUpdate({
            userName: this.ruleForm.userName,
            id: this.ruleForm.id,
            phone: this.ruleForm.phone,
            roleId: this.ruleForm.roleId,
            type:type,
            roleName: this.ruleForm.roleName,
            departmentId: this.ruleForm.departmentId,
            departmentName: this.ruleForm.departmentName
          })
            .then(res => {
              this.$message.success(res.message);
              this.getUserList();
              this.getRoleList()
              this.addShow = false;
              
            })
            .catch(err => {
              this.$message.error(err.message);
            });
        }
      });
    },
    // 删除
    handleDelete(item) {
      let { id } = item;
      this.$confirm("此操作将永久删除该用户名称，确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$post(`/user/deleteById/${id}`).then(res => {
            console.log(res);
            if (res.code == 200) {
              this.$message({
                message: res.message,
                type: "success"
              });
              this.size = 10;
              if (this.total % 10 == 1) {
                this.page--;
              }
              this.getUserList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    // 获取用户列表
    getUserList() {
      this.loading = true;
      this.$get(`/user/page/${this.page}/${this.size}`).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.loading = false;
      });
    },
    // 获取角色，部门列表
    getRoleList() {
      userFindAll().then(res => {
        this.options = res.data;
      });
      userDepartment().then(res => {
        this.departList = res.data;
      });
    },
    //取消
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.addShow = false;
    },
    // 分页切换
    handleCurrent(page) {
      this.page = page;
      this.getUserList();
    },
    // 查询
    handleSearch() {
      this.$get("./user/page/1/10", {
        userName: this.value
      }).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },
    handleReset() {
      this.value = "";
      this.getUserList();
    }
  },
  created() {
    this.getUserList();
    this.getRoleList();
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
.dialogAdd {
  color: #1d1d1d;
  font-weight: bold;
}
.contentAdd {
  .el-form {
    margin-top: calc(25 / 1080 * 100vh);
    .el-form-item {
      margin-bottom: calc(10 / 1080 * 100vh);
    }
    ::v-deep .el-form-item__label {
      color: #666;
      text-align: right;
      width: calc(100 / 1920 * 100vw);
      margin-right: calc(10 / 1920 * 100vw);
    }
  }
  .el-input {
    width: calc(250 / 1920 * 100vw);
  }
  ::v-deep .el-input__inner {
    border: none;
    border-radius: 4px;
    background: #f3f4f8;
  }
  .el-select {
    width: calc(250 / 1920 * 100vw);
  }
}
::v-deep .el-dialog {
  box-sizing: border-box;
  background: #4073b6;
  border-radius: 16px;
  width: calc(388 / 1920 * 100vw) !important;
  .el-dialog__header {
    background: #fff;
    margin-top: 4px;
    border-radius: 16px 16px 0 0;
  }
  .el-dialog__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 0 0 16px 16px;
    padding: calc(15 / 1080 * 100vh) 0;
     .el-button:nth-child(1):hover {
      background: #eaeaea;
      border: 1px #eaeaea solid;
      color: #313131;
    }
  }
  // 弹框主体
  .el-dialog__body {
    background: #fff;
    height: calc(395 / 1080 * 100vh);
    // 菜单选框
    .el-checkbox-group {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      label {
        width: 34% !important;
        height: calc(25 / 1080 * 100vh);
        margin-right: 0;
      }
    }
  }
}
::v-deep .el-dialog__footer {
  padding-right: calc(35 / 1920 * 100vw);
}
::v-deep .el-input__icon {
  font-size: calc(10 / 1920 * 100vw) !important;
}

::v-deep .menu_left {
  .el-input__inner {
    width: calc(200 / 1920 * 100vw);
  }
}
::v-deep .el-form-item__error {
  position: absolute;
  top: calc(48 / 1080 * 100vh);
  left: calc(125 / 1920 * 100vw) !important;
}
::v-deep .el-icon-circle-check:before {
  content: "" !important;
}
</style>
