import {
  get,
  post
}
  from '../common/axiosHttp'
// 添加用户
export const userAdd = params => post("/user/add", params);
// 编辑角色
export const userUpdate = params => post("/user/update", params);

// 查询所有角色
export const userFindAll = params => get("/role/findAll", params);

// 查询所有部门
export const userDepartment = params => get("/department/findAll", params);




